import { paths } from 'utilities/routes';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { ArticleView } from 'utilities/components/article/component/view';
import { HeadlineView } from 'utilities/components/headline/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { ButtonView } from 'utilities/components/button/component/view';

import { generateSlug } from 'utilities/helpers/generateSlug';

import './view.scss';

SwiperCore.use([Navigation]);

export const Articles = ({ articles, types, title }) => {
    const [type, setType] = useState(types?.length ? types[0] : '');
    const [filteredArticles, setFilteredArticles] = useState(() => {
        if (!type) {
            return articles;
        }
        return articles?.filter((currentValue) => currentValue?.type === type);
    });

    const currentLink = `${paths.artykuly}/${generateSlug(type)}`;

    useEffect(() => {
        setFilteredArticles(() => articles?.filter((article) => article?.type === type));
    }, [type, setFilteredArticles]);

    const articlesCarousel = (
        <section className="carousel">
            <div
                className={ classnames({
                    margin: !types?.length
                }) }
            >
                <HeadlineView text={ title } />
            </div>

            {types?.length ? (
                <Swiper className="carousel__types" slidesPerView="auto" centerInsufficientSlides>
                    {types.map((currentValue, index) => (
                        <SwiperSlide className="types__type" key={ index }>
                            <button
                                className={ classnames('type__button', {
                                    'type__button--active': type === currentValue
                                }) }
                                type="button"
                                onClick={ () => setType(type === currentValue ? type : currentValue) }
                            >
                                {currentValue}
                            </button>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : null}

            <Swiper
                className="content__carousel"
                navigation={{
                    nextEl: '.articles--next',
                    prevEl: '.articles--previous'
                }}
                slidesPerView="auto"
                centerInsufficientSlides
                loop={ filteredArticles?.length > 3 }
                loopedSlides={ filteredArticles?.length || 0 }
                key={ `index-carousel-${generateSlug(type)}` }
            >
                {filteredArticles?.length
                    ? filteredArticles.map((currentValue, index) => (
                        <SwiperSlide className="carousel__item" key={ index }>
                            <ArticleView
                                alternative_id={ currentValue?.alternative_id }
                                image_main={ currentValue?.image_main }
                                author={ currentValue?.author }
                                created_at={ currentValue?.created_at }
                                slug={ currentValue?.slug }
                                title={ currentValue?.title }
                                content={ currentValue?.lead }
                                reading_time={ currentValue?.reading_time }
                                link="/"
                            />
                        </SwiperSlide>
                    ))
                    : []}
                <ButtonsView name="articles" />
            </Swiper>
            <ButtonView className="button articles-button" text="Wszystkie artykuły" link={ currentLink } />
        </section>
    );

    return filteredArticles.length ? articlesCarousel : null;
};

Articles.defaultProps = {
    articles: [],
    types: [],
    title: ''
};
Articles.propTypes = {
    articles: PropTypes.instanceOf(Array),
    types: PropTypes.instanceOf(Array),
    title: PropTypes.string
};
