import React from 'react';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { Img } from 'utilities/components/img/component';

import fireExtinguisher from 'assets/images/smart/fire-extinguisher.svg';
import antiBurglary from 'assets/images/smart/anti-burglary.svg';
import acoustic from 'assets/images/smart/acoustic.svg';
import flame from 'assets/images/investments/flame.svg';
import shield from 'assets/images/investments/shield.svg';
import music from 'assets/images/investments/music.svg';

import './view.scss';

export const ParametersView = () => (
    <section className="parameters">
        <HeadlineView text="Wyjątkowe parametry" />
        <div className="parameters__container">
            <div className="parameters__item">
                <div className="parameters__icon">
                    <Img src={ fireExtinguisher } alt="gaśnica" />
                </div>
                <div className="parameters__content">
                    <h4 className="parameters__headline">Przeciwpożarowość</h4>
                    <div className="parameters__info">
                        <Img src={ flame } alt="płomień" />
                        <span>EI 30</span>
                        <Img src={ flame } alt="płomień" />
                        <span>EI 60</span>
                    </div>
                </div>
            </div>
            <div className="parameters__item">
                <div className="parameters__icon">
                    <Img className="parameters__icon" src={ antiBurglary } alt="włamywacz" />
                </div>
                <div className="parameters__content">
                    <h4 className="parameters__headline">Antywłamaniowość</h4>
                    <div className="parameters__info">
                        <Img src={ shield } alt="tarcza" />
                        <span>RC2</span>
                        <Img src={ shield } alt="tarcza" />
                        <span>RC3</span>
                    </div>
                </div>
            </div>
            <div className="parameters__item">
                <div className="parameters__icon">
                    <Img className="parameters__icon" src={ acoustic } alt="drzwi" />
                </div>
                <div className="parameters__content">
                    <h4 className="parameters__headline">Akustyka</h4>
                    <div className="parameters__info">
                        <Img src={ music } alt="nuta" />
                        <span>34dB, 39dB, 44dB</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
);