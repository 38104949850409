import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { HeadlineView } from 'utilities/components/headline/component/view';

import './view.scss';

SwiperCore.use([Navigation, Pagination]);

export const Advantages = ({ data, color, title }) => (
    <section className="advants" style={{ '--color': color }}>
        <HeadlineView text={ title } />
        {/* Mobile view */}
        <Swiper
            className="advants__carousel"
            navigation={{
                nextEl: '.buttons__button--next',
                prevEl: '.buttons__button--previous'
            }}
            pagination={{ clickable: true }}
            loop
            loopedSlides={ data.length }
            slidesPerView={ 1 }
            centerInsufficientSlides
        >
            {
                data.map((item, index) => (
                    <SwiperSlide className="advants__item" key={ index }>
                        <div className="item">
                            <div className="item__title-wrapper">
                                <div className="item__dot" style={{ backgroundColor: color }} />
                                <p className="item__title">
                                    <span className="item__title--bold">{ item.title1 }</span>
                                    { item.title2 }
                                </p>
                            </div>
                            <div className="item__image" style={{ backgroundImage: `url(${item.image})` }} />
                            <p className="item__description" style={{ backgroundColor: `${color}dd` }}>{ item.description }</p>
                        </div>
                    </SwiperSlide>
                ))
            }
            <ButtonsView />
        </Swiper>

        {/* Desktop view */}
        <div className="advants--desktop">
            {
                data.map((item, index) => (
                    <div className="item__wrapper" key={ index }>
                        <div className="item" key={ index }>
                            <div className="item__title-wrapper">
                                <div className="item__dot" style={{ backgroundColor: color }} />
                                <p className="item__title">
                                    <span className="item__title--bold">{ item.title1 }</span>
                                    { item.title2 }
                                </p>
                            </div>
                            <div className="item__image" style={{ backgroundImage: `url(${item.image})` }} />
                            <p className="item__description" style={{ backgroundColor: `${color}dd` }}>{ item.description }</p>
                        </div>
                    </div>
                ))
            }
        </div>
    </section>
);

Advantages.propTypes = {
    data: PropTypes.instanceOf(Array).isRequired,
    color: PropTypes.string,
    title: PropTypes.string.isRequired
};

Advantages.defaultProps = {
    color: '#A79A70'
};