import React from 'react';
import PropTypes from 'prop-types';
import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const Offer = ({
    image, color, description, link
}) => (
    <section className="offer" style={{ '--color': color }}>
        <div className="offer__container">
            <h4 className="offer__headline">Dowiedz się więcej o naszej ofercie</h4>
            <p className="offer__description">
                { description }
            </p>
            <ButtonView text="Zobacz katalog" link={ link } download target="_blank" />
            <Img className="offer__image" src={ image } alt="katalog" />
        </div>
    </section>
);

Offer.propTypes = {
    color: PropTypes.string,
    image: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.string.isRequired
};

Offer.defaultProps = {
    color: '#4a548c',
    description: ''
};