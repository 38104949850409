import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { OfferView } from './view';

export const Offer = () => {
    const data = useStaticQuery(graphql`
        query GetSmartData {
            allFolders(filter: {
                id: {
                    ne: "dummy"
                }
                assortment: {
                    name: {
                        eq: "Smart"
                    }
                }
            }) {
                nodes {
                    brand_color
                    folder_file
                    folderpage_thumbnail_img
                }
            }
        }
    `);

    return (<OfferView data={ data?.allFolders?.nodes[0] } />);
};