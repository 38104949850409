import React from 'react';
import PropTypes from 'prop-types';
import { paths } from 'utilities/routes';

import { Carousel } from 'features/index/carousel/component';
import { DoorView } from 'utilities/components/door/component/view';

import './view.scss';

export const ModelsView = ({ models }) => (
    <Carousel
        headline="Modele Barański Smart"
        buttonText="Zobacz więcej"
        buttonLink={ `${paths.produkty}/drzwi-techniczne/baranski-smart` }
        modifier="models__item"
        items={ models }
        slidesPerView="auto"
        loopBreakpoint={ 7 }
    >
        <DoorView inSwiper />
    </Carousel>
);

ModelsView.propTypes = {
    models: PropTypes.instanceOf(Array).isRequired
};