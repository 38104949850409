import React from 'react';

import advantage1 from 'assets/images/smart/advantages/1.png';
import advantage2 from 'assets/images/smart/advantages/2.png';
import advantage3 from 'assets/images/smart/advantages/3.png';
import advantage4 from 'assets/images/smart/advantages/4.png';
import advantage5 from 'assets/images/smart/advantages/5.png';
import advantage6 from 'assets/images/smart/advantages/6.png';

import { AdvantagesView } from './view';

export const Advantages = () => {
    const data = [
        {
            title1: 'Drewniany ramiak skrzydła',
            title2: 'w standardzie',
            description: 'Wyjątkowa sztywność konstrukcji i zwiększona wytrzymałość skrzydeł na zginanie.',
            image: advantage1
        },
        {
            title1: 'Pakiet wyciszający 34B',
            title2: 'w standardzie',
            description: 'Komfort i prywatność zapewnione dzięki wysokiej dźwiękoszczelności drzwi.',
            image: advantage2
        },
        {
            title1: 'Okleinowanie bez widocznych łączeń',
            title2: 'w standardzie',
            description: 'Estetyka, jakość i dokładność wykonania.',
            image: advantage3
        },
        {
            title1: 'Dwa zamki antywłamaniowe',
            title2: 'w standardzie',
            description: 'Bezpieczeństwo i szczególna odporność na próby włamania.',
            image: advantage4
        },
        {
            title1: 'Klamka Medos w trzech kolorach',
            title2: 'w standardzie',
            description: 'Wysokiej jakości okucia anodowane. Unikatowy design, solidność i precyzja wykonania.',
            image: advantage5
        },
        {
            title1: 'Wytrzymałe okleiny i forniry',
            title2: 'w standardzie',
            description: 'Duża odporność na zarysowania, ścieranie czy zawilgocenie.',
            image: advantage6
        }
    ];

    return (<AdvantagesView data={ data } color="#e25735" />);
};