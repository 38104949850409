import React from 'react';
import PropTypes from 'prop-types';
import { paths } from 'utilities/routes';

import { Articles } from 'utilities/components/articles/component/view';

export const ArticlesView = ({ articles }) => (
    <Articles
        articles={ articles }
        title="Technologie"
        buttonText="Wszystkie artykuły"
        buttonLink={ paths.wszystkoODrzwiach }
    />
);

ArticlesView.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired
};