import React from 'react';

import { Img } from 'utilities/components/img/component';

import baranskiSmart from 'assets/images/baranski-smart.svg';

import 'utilities/components/description/component/view.scss';

// TODO - use universal component from 'src/utilities/components/description/component/view.jsx' if possible (bold words)
export const DescriptionView = () => (
    <section className="description">
        <Img src={ baranskiSmart } alt="barański optiom" />
        <p className="description__text">
            Barański Smart to drzwi do zadań specjalnych. Cechują je
            <span className="description__text--bold"> wyjątkowe parametry w zakresie antywłamaniowości, akustyki oraz przeciwpożarowości</span>
            . Zabezpieczają przed włamaniami, chronią przed niepożądanymi czynnikami środowiskowymi, jak hałas czy chłód, a dodatkowo zapobiegają rozprzestrzenianiu się ognia w przypadku pożaru. W mieszkaniach idealnie sprawdzają się jako drzwi klatkowe. W prywatnych domach znajdą zastosowanie np. do oddzielenia pomieszczeń gospodarczych od części mieszkalnej.
        </p>
    </section>
);