import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { BannerView } from 'utilities/components/banner/component/view';
import { DescriptionView } from 'features/smart/description/component/view';
import { Pattern } from 'features/smart/pattern/component';
import { Models } from 'features/smart/models/component';
import { Advantages } from 'features/smart/advantages/component';
import { Parameters } from 'features/smart/parameters/component';
import { Implementations } from 'features/smart/implementations/component';
import { Articles } from 'features/smart/articles/component';
import { Offer } from 'features/smart/offer/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Smart = () => {
    const breadcrumbs = [routes.home, routes.smart];
    const data = useStaticQuery(graphql`
        query GetBannerSmart {
            banners(site_type: { eq: "Marka Smart" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Smart');

    return (
        <main className="smart">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Nasze marki - Barański Drzwi Smart' }
                description={
                    metaDescription
                    || 'Drzwi wejściowe, drzwi klatkowe, drzwi antywłamaniowe. Drzwi Barański Smart to drzwi do zadań specjalnych. Poznaj naszą linie.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Drzwi do zadań specjalnych dla rodzin i biznesu"
                subtitle="Drzwi klatkowe i techniczne"
                type="smart"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <DescriptionView />
            <Advantages />
            <Parameters />
            <Pattern />
            <Models />
            <Offer />
            <Articles />
            <Implementations />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Smart;
