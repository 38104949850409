import React from 'react';

import { PatternView } from './view';
import doorSmart from 'assets/images/smart/drzwi-smart.png';
import doorSmartTradycyjne from 'assets/images/smart/pattern/smart_tradycyjne.png';
import doorSmartWyjatkowe from 'assets/images/smart/pattern/smart_wyjatkowe.png';

export const Pattern = () => {
    const data = {
        image: doorSmart,
        title: 'Wzór na Twoje potrzeby',
        subtitle: '',
        links: [
            {
                title: 'Nowoczesne',
                image: doorSmart
            },
            {
                title: 'Tradycyjne',
                image: doorSmartTradycyjne
            },
            {
                title: 'Wyjątkowe',
                image: doorSmartWyjatkowe
            }
        ],
        description: 'Nawet drzwi do zadań specjalnych powinny cieszyć oko i zapewniać miłe wrażenia estetyczne. Dlatego modele Barański Smart dostępne są w kilku wariantach stylistycznych. Oferujemy zarówno nowoczesne, jak i tradycyjne wzory, które zadowolą klientów o różnych gustach.'
    };

    return (<PatternView data={ data } color="#e25735" isReverse />);
};