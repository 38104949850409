import React from 'react';
import PropTypes from 'prop-types';

import { Offer } from 'utilities/components/offer/component/view';

export const OfferView = ({ data }) => (
    <Offer
        image={ `${process.env.GATSBY_URL}/${data?.folderpage_thumbnail_img}` }
        color={ data?.brand_color }
        link={ `${process.env.GATSBY_URL}/${data?.folder_file}` }
    />
);

OfferView.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired
};