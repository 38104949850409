import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import { ArticlesView } from './view';

export const Articles = () => {
    const data = useStaticQuery(graphql`
        query {
            allArticles(filter: {
                is_active: {
                    eq: 1
                }
                type: {
                    eq: "Technologie"
                }
            }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    subtype
                    title
                    type
                    reading_time
                }
            }
        }
    `);

    const newArticles = _.uniqBy(data?.allArticles?.nodes?.filter((article) => article?.subtype?.toLowerCase()?.includes('smart')), 'alternative_id');

    return (<ArticlesView articles={ newArticles } />);
};