import React from 'react';
import PropTypes from 'prop-types';
import { Pattern } from 'utilities/components/pattern/component/view';

export const PatternView = ({ data, color, isReverse }) => (
    <Pattern data={ data } color={ color } isReverse={ isReverse } />
);

PatternView.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    color: PropTypes.string,
    isReverse: PropTypes.bool
};

PatternView.defaultProps = {
    color: ' #4a548c',
    isReverse: false
};