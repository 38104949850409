import React from 'react';
import PropTypes from 'prop-types';

import trapezoid from 'assets/images/index/trapezoid.svg';
import trapezoidPremium from 'assets/images/index/trapezoid--premium.svg';
import trapezoidOptimo from 'assets/images/index/trapezoid--optimo.svg';
import trapezoidSmart from 'assets/images/index/trapezoid--smart.svg';

import './view.scss';

const images = {
    default: trapezoid,
    premium: trapezoidPremium,
    optimo: trapezoidOptimo,
    smart: trapezoidSmart
};

export const BannerView = ({
    image, headline, subtitle, type, x
}) => (
    <section className="banner" style={{ backgroundImage: `url("${images[type]}"), url("${image}")`, backgroundPositionX: `left, ${x}%` }}>
        <div className="banner__text">
            <h1 className="banner__headline">{ headline }</h1>
            { subtitle
            && <h2 className="banner__subtitle">{ subtitle }</h2> }
        </div>
    </section>
);

BannerView.propTypes = {
    image: PropTypes.node.isRequired,
    headline: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(images)),
    x: PropTypes.number.isRequired
};

BannerView.defaultProps = {
    subtitle: undefined,
    type: 'default'
};