import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Img } from 'utilities/components/img/component';

import './view.scss';

SwiperCore.use([Pagination, Navigation]);

export const Pattern = ({ data, color, isReverse }) => (
    <section className={ `pattern ${isReverse ? 'pattern--reverse' : ''}` } style={{ '--color': color, '--isReverse': isReverse }}>
        <div className="pattern__container">
            <h2 className="container__headline">Wzory dla każdego wnętrza</h2>
            <div className="container__image-wrapper">
                { data?.links.map((link) => (
                    <figure className="container__content">
                        <Img src={ link?.image } alt={ data?.title } className="container__image" />
                        <figcaption className="container__description">{ link?.title }</figcaption>
                    </figure>
                )) }
            </div>
            <div className="container__image-wrapper--mobile">
                <Swiper
                    slidesPerView={ 1 }
                    pagination={{ clickable: true }}
                    navigation={{ clickable: true }}
                    loop
                    loopedSlides={ data?.links?.length }
                    centerInsufficientSlides
                    spaceBetween={ 15 }
                    allowTouchMove
                >
                    {data.links.map((link) => (
                        <SwiperSlide key={ link?.title }>
                            <figure className="container__content">
                                <Img src={ link?.image } alt={ data?.title } className="container__image--mobile" />
                                <figcaption className="container__description">{ link?.title }</figcaption>
                            </figure>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    </section>
);

Pattern.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    color: PropTypes.string,
    isReverse: PropTypes.bool
};

Pattern.defaultProps = {
    color: ' #4a548c',
    isReverse: false
};